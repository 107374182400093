import * as React from 'react';
import * as Sentry from '@sentry/browser';

import { MediaResourceT } from './audioApi';
import { AudioBlobT } from '../types';

type CallbackFnT = (clip: AudioBlobT) => void;
interface OptionsT {
  enabled: boolean;
}

interface CustomRecorderApi {
  start: () => void;
  stop: () => void;
}

/**
 * TODOS:
 * - getUserMedia starts the microphone access, which happens on createMediaResource
 */

// Suspense notes:
// to lazily init mediaApi we'd have to have a suspense cache and a stable id.
// additionally we'd have to to temporary retains as components suspend before their commit phase
// relay-experimental does this with a temporaryRetain combined with a permanentRetain
// ref https://github.com/facebook/relay/blob/983cfe7086bf1234507a9c7d14989cf912016589/packages/relay-experimental/QueryResource.js#L188
// Alt: react-cache https://github.com/facebook/react/blob/master/packages/react-cache/src/ReactCache.js
// const mediaApi = createMediaResource({
//   wantedTypes: ['audio/ogg;codecs=opus', 'audio/webm;codecs=opus', 'audio/wav'],
// });

export function useAudioRecorder(
  mediaResource: MediaResourceT,
  callback: CallbackFnT,
  options: OptionsT
): [MediaRecorder, CustomRecorderApi] {
  const { enabled = true } = options;

  const callbackRef = React.useRef<CallbackFnT>();
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const audioRecorder = mediaResource.read();

  /**
   * Use a mutable ref to hold config so that event listeners
   * don't have to be unmounted and lose context with every change.
   * ... or perhaps that's exactly what it should do? The event listeners
   * would me unmounted as soon as the recorder is disabled
   */
  const configRef = React.useRef({ enabled });
  React.useEffect(() => {
    Object.assign(configRef.current, { enabled });
  }, [enabled]);

  React.useEffect(() => {
    let chunks: BlobPart[] = [];
    const onData = (e: Event) => {
      chunks.push(e.data as BlobPart);
    };

    const onStop = () => {
      const blob = new Blob(chunks, { type: audioRecorder.mimeType });
      chunks = [];

      const { enabled } = configRef.current;
      if (enabled && callbackRef.current) {
        callbackRef.current({
          blob,
          mimeType: audioRecorder.getMimeType(),
          audioURL: window.URL.createObjectURL(blob),
        });
      }
    };

    const onError = (err: any) => {
      Sentry.withScope((scope) => {
        scope.setExtra('module', 'MediaRecorder');
        Sentry.captureException(err);
      });
    };

    audioRecorder.addEventListener('stop', onStop);
    audioRecorder.addEventListener('dataavailable', onData);
    audioRecorder.addEventListener('error', onError);

    return () => {
      audioRecorder.removeEventListener('stop', onStop);
      audioRecorder.removeEventListener('dataavailable', onData);
      audioRecorder.removeEventListener('error', onError);
    };
  }, [audioRecorder, configRef, callbackRef]);

  const recorderApi = React.useMemo(
    () => ({
      start: () => {
        audioRecorder.start();
      },
      stop: () => {
        if (audioRecorder.state !== 'inactive') {
          audioRecorder.stop();
        }
      },
    }),
    [audioRecorder]
  );

  return [audioRecorder, recorderApi];
}
