import * as React from 'react';

function LogoCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 594.77 595.279" {...props}>
      <path
        fill="currentColor"
        d="M435.35 335.85l-43.981-17.262V203.631c0-7.986-6.471-14.459-14.472-14.459-8 0-14.471 6.473-14.471 14.459v103.594l-24.232-9.506V168.361c0-7.99-6.478-14.463-14.459-14.463-8.006 0-14.473 6.473-14.473 14.463v118l-24.248-9.52v-108.48c0-7.99-6.478-14.463-14.475-14.463-7.99 0-14.464 6.473-14.464 14.463v97.123l-24.231-9.512V203.63c0-7.986-6.49-14.459-14.477-14.459-7.982 0-14.467 6.473-14.467 14.459v40.984l-33.418-13.119c-7.463-2.941-15.846.766-18.754 8.188-2.927 7.438.742 15.822 8.188 18.746l43.983 17.268V390.65c0 7.988 6.484 14.467 14.467 14.467 7.986 0 14.477-6.479 14.477-14.467V287.047l24.231 9.525v129.355c0 7.996 6.474 14.461 14.464 14.461 7.997 0 14.475-6.465 14.475-14.461V307.916l24.248 9.525v108.486c0 7.996 6.467 14.461 14.473 14.461 7.981 0 14.459-6.465 14.459-14.461v-97.131l24.232 9.514v52.34c0 7.988 6.471 14.467 14.471 14.467 8.001 0 14.472-6.479 14.472-14.467v-40.986l33.421 13.119a14.257 14.257 0 005.281 1.012c5.775 0 11.231-3.488 13.464-9.191 2.927-7.442-.74-15.838-8.184-18.754z"
      />
      <path
        fill="currentColor"
        d="M297.133 592.279c-162.735 0-295.145-132.406-295.145-295.139C1.988 134.406 134.397 2 297.133 2c162.732 0 295.136 132.406 295.136 295.141 0 162.732-132.404 295.138-295.136 295.138zm0-561.34c-146.787 0-266.195 119.418-266.195 266.201 0 146.785 119.408 266.197 266.195 266.197 146.783 0 266.201-119.412 266.201-266.197 0-146.783-119.418-266.201-266.201-266.201z"
      />
    </svg>
  );
}

export default LogoCircle;
