import * as React from 'react';
import * as Sentry from '@sentry/browser';

import { uploadAudioClip } from '../api';
import { AudioClipT } from '../types';
import { useAutoTrackingRef } from '../hooks';
import { Button } from './Button';
import { events } from '../analytics';

const promptToSave = () =>
  confirm(
    'Are you ready to upload your words?\nIf not, press cancel now,' +
      ' and then press Upload once you are ready.'
  );

interface StateT {
  state: 'idle' | 'uploading' | 'paused';
  uploadInProgress: boolean;
  errorMessage?: string;
}
type ActionT =
  | { type: 'START_UPLOAD' }
  | { type: 'UPLOAD_STARTED' }
  | { type: 'UPLOAD_PAUSED' }
  | { type: 'UPLOAD_SUCCESS' }
  | { type: 'UPLOAD_ERROR'; message: string };

const getInitialState = (): StateT => ({
  state: 'idle',
  uploadInProgress: false,
});
const uploadReducer = (state: StateT, action: ActionT): StateT => {
  console.log('uploadReducer', JSON.stringify({ state, action }, null, 2));

  switch (action.type) {
    case 'START_UPLOAD':
      return { ...state, state: 'uploading' };
    case 'UPLOAD_STARTED':
      return { ...state, uploadInProgress: true };
    case 'UPLOAD_PAUSED':
      return { ...state, state: 'paused' };
    case 'UPLOAD_ERROR':
      return {
        ...state,
        state: 'paused',
        uploadInProgress: false,
        errorMessage: action.message,
      };
    case 'UPLOAD_SUCCESS':
      return { ...state, uploadInProgress: false, errorMessage: undefined };
    default:
      return state;
  }
};

const errorDict: Record<string, boolean> = {};

interface UploaderPropsT {
  clips: AudioClipT[];
  onDone: () => void;
  onClipUploaded: (clipId: string) => void;
}
export function Uploader(props: UploaderPropsT) {
  const { clips, onDone, onClipUploaded } = props;

  const onDoneRef = useAutoTrackingRef(onDone);
  const onClipUploadedRef = useAutoTrackingRef(onClipUploaded);

  const [
    { state, uploadInProgress, errorMessage },
    dispatch,
  ] = React.useReducer(uploadReducer, getInitialState());

  React.useEffect(() => {
    if (promptToSave()) {
      window.mixpanel?.track(events.UPLOAD_STARTED, { promptToSave: true });
      dispatch({ type: 'START_UPLOAD' });
    }
  }, []);

  const nextClip = React.useMemo(() => {
    return clips.find((_clip) => !_clip.uploaded);
  }, [clips]);

  const uploadCount = React.useMemo(
    () => clips.filter((clip) => clip.uploaded).length,
    [clips]
  );

  React.useEffect(() => {
    if (uploadInProgress || state !== 'uploading') {
      return;
    }

    if (!nextClip) {
      console.log(`Upload complete`);
      onDoneRef.current();
      return;
    }

    dispatch({ type: 'UPLOAD_STARTED' });
    console.log(`Uploading "${nextClip.word}" (${nextClip.mimeType})`);

    uploadAudioClip(nextClip)
      .then(() => {
        onClipUploadedRef.current(nextClip.id);
        dispatch({ type: 'UPLOAD_SUCCESS' });
      })
      .catch((err) => {
        console.log(`Failed to upload "${nextClip.word}"`, err);
        if (err && !errorDict[err.message]) {
          errorDict[err.message] = true;
          Sentry.captureException(err);
        }
        dispatch({
          type: 'UPLOAD_ERROR',
          message:
            err.message || 'Failed to upload audio clip, please try again',
        });
      });
  }, [nextClip, state, uploadInProgress, onDoneRef, onClipUploadedRef]);

  const onStart = () => {
    window.mixpanel?.track(events.UPLOAD_STARTED, {
      promptToSave: false,
    });
    dispatch({ type: 'START_UPLOAD' });
  };

  const onPause = () => {
    window.mixpanel?.track(events.UPLOAD_PAUSED);
    dispatch({ type: 'UPLOAD_PAUSED' });
  };

  return (
    <>
      <h2 className="text-3xl mb-2">Uploaded</h2>

      <div className="text-center text-lg mb-3">
        {uploadCount} / {clips.length}
      </div>

      <section className="py-2">
        <div className="flex justify-center mb-5">
          {state === 'uploading' ? (
            <Button onClick={onPause}>Pause upload</Button>
          ) : (
            <Button onClick={onStart}>Start upload</Button>
          )}
        </div>

        {errorMessage && (
          <p className="italic text-base text-red-500">{errorMessage}</p>
        )}
      </section>
    </>
  );
}
