import * as React from 'react';

export const LegalText = () => {
  return (
    <>
      <p className="legal mb-2">
        Participant Agreement Hoopteller and the other party identified below
        (“You”) agree as follows:
      </p>
      <p className="legal mb-2">
        1. By participating, this website will ask for permission to use your
        microphone and prompt you to speak single words which we will record
        (the “Session”). These words will appear on-screen, one after another,
        over the course of around three minutes, with short pauses in between.
        When the microphone is active, you’ll see an oscilloscope display
        showing a waveform reflecting the audio received. Clips will be
        displayed in the bottom half of the web page where you can review and
        delete any of them locally before submitting them to Hoopteller. Clips
        will only be sent to Hoopteller when you press the ‘Upload’ button, or
        the ‘OK’ button on the dialog that appears once you’ve completed all the
        requested words. At any point you can press the ‘Pause’ button to pause
        the recording process. Navigating away from this website will remove all
        local copies of the clips.
      </p>
      <p className="legal mb-2">
        2. Your participation is voluntary and you may choose to cease
        participation at any time. You will not be compensated for your
        participation.
        <b>
          If you choose to participate in this recording session, you must be in
          a quiet room with no other voices and only speak the requested words.
          A closed room is a good place for this
        </b>
        .
      </p>
      <p className="legal mb-2">
        3. By participating, you agree that Hoopteller may use the clips to
        develop and improve its products. You understand that your voice alone
        or in combination with other information could identify you. You
        specifically waive and release any potential intellectual property
        right, right of publicity, or right of privacy claim against Hoopteller
        or others for use or sharing of your clips.
      </p>
      <p className="legal mb-2">
        4. HOOPTELLER MAKES NO WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO THE
        STUDY AND EXPRESSLY DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED. IN NO
        EVENT WILL EITHER PARTY BE LIABLE FOR ANY DAMAGES UNDER THIS RELEASE IN
        EXCESS OF $100.
      </p>
      <p className="legal mb-2">
        5. This agreement does not create any agency or partnership
        relationship. This agreement is not assignable or transferable by you.
        This agreement is the parties’ entire agreement on this topic,
        superseding any prior or contemporaneous agreements. Any amendments must
        be in writing. Failure to enforce any of provisions of this agreement
        will not constitute a waiver.
      </p>
      <p className="legal mb-2">
        By clicking ‘Proceed you confirm that you are at least eighteen years of
        age, you understand the points above, and consent to the collection,
        use, and sharing of the Clips.
      </p>
    </>
  );
};
