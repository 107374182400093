import * as React from 'react';
import ReactDom from 'react-dom';

import { Header, ErrorBoundary } from './components';

type LazyComponent = React.LazyExoticComponent<() => JSX.Element>;
const screens: Record<string, LazyComponent | undefined> = {
  WelcomeScreen: React.lazy(() => import('./WelcomeScreen')),
  LegalScreen: React.lazy(() => import('./LegalScreen')),
  RecordingScreen: React.lazy(() => import('./RecordingScreen')),
  ThanksScreen: React.lazy(() => import('./ThanksScreen')),
};

// this route map is for when we manually set the path in development
// eg. https://localhost:1234/record
const routeMap: Record<string, string> = {
  '/': 'WelcomeScreen',
  '/welcome': 'WelcomeScreen',
  '/legal': 'LegalScreen',
  '/start': 'RecordingScreen',
  '/record': 'RecordingScreen',
  '/thanks': 'ThanksScreen',
};

const PageNotFound = () => {
  return (
    <div className="mt-10">
      <h3 className="text-xl text-center">Page not found</h3>
    </div>
  );
};

const Root = () => {
  const pathname = window.location.pathname || '/';
  const page_component =
    process.env.NODE_ENV === 'development'
      ? routeMap[pathname]
      : window.page_component;

  const PageContent = screens[page_component] || PageNotFound;

  // TODO: more manual testing of mixpanel
  // - enable mixpanel script in index.html
  // - test link tracking
  // - test page tracking
  // - reduce noise!
  // - track start/pause recording?

  React.useEffect(() => {
    window.mixpanel?.track(`${page_component}`);
  }, [page_component]);

  return (
    <div className="max-w-3xl m-auto px-5">
      <Header />
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <PageContent />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  );
};

ReactDom.render(<Root />, document.getElementById('app-root'));
