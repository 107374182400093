import * as React from 'react';
import clsx from 'clsx';

import { Countdown, AudioVisualizer, Button, useProgressAnimation } from '.';
import { MediaResourceT, useAudioRecorder } from '../audioRecorder';

import { AudioClipT, AudioBlobT } from '../types';

const PAUSE_DURATION = 1000;
const RECORDING_DURATION = 1500;
const COUNTDOWN_DURATION = 3000;

interface AudioRecorderPropsT {
  onDone: () => void;
  clips: AudioClipT[];
  clipDescriptions: Array<{ id: string; word: string } | undefined>;
  onAudioClip: (clip: AudioClipT) => void;
  mediaResource: MediaResourceT;
}
export function AudioRecorder(props: AudioRecorderPropsT) {
  const { onDone, clips, onAudioClip } = props;

  /**
   * Set up words, and keep track of current word until we are done
   */
  const currentClipDesc = props.clipDescriptions[clips.length];
  React.useEffect(() => {
    if (!currentClipDesc) {
      onDone();
    }
  }, [currentClipDesc, onDone]);

  /**
   * Set up recorder state, and handle reception of audio blobs
   */
  const onAudioBlob = React.useCallback(
    (blob: AudioBlobT) => {
      if (!currentClipDesc) {
        return;
      }

      onAudioClip({
        ...blob,
        id: currentClipDesc.id,
        word: currentClipDesc.word,
        uploaded: false,
      });
    },
    [currentClipDesc, onAudioClip]
  );

  type RecorderStateT = 'countdown' | 'recording' | 'paused';
  const [recorderState, setRecorderState] = React.useState<RecorderStateT>(
    'countdown'
  );

  const [mediaRecorder, recorderApi] = useAudioRecorder(
    props.mediaResource,
    onAudioBlob,
    { enabled: recorderState === 'recording' }
  );

  /**
   * Recording/pause loop
   */
  const [progressAnimation, animationApi] = useProgressAnimation();

  React.useEffect(() => {
    if (recorderState === 'countdown') {
      animationApi.run(COUNTDOWN_DURATION);
    }
    if (recorderState === 'paused') {
      animationApi.stop();
    }
  }, [recorderState]);

  const [wordDisplay, setWordDisplay] = React.useState('');
  React.useEffect(() => {
    if (!currentClipDesc || recorderState !== 'recording') {
      return;
    }

    const currentWord = currentClipDesc.word;

    let timeoutId = setTimeout(() => {
      setWordDisplay(currentWord);
      recorderApi.start();
      animationApi.run(RECORDING_DURATION);

      timeoutId = setTimeout(() => {
        recorderApi.stop();
        setWordDisplay('');
      }, RECORDING_DURATION);
    }, PAUSE_DURATION);

    return () => {
      clearTimeout(timeoutId);
      recorderApi.stop();
      animationApi.stop();
      setWordDisplay('');
    };
  }, [recorderApi, animationApi, currentClipDesc, recorderState]);

  return (
    <>
      <p className="text-lg text-center mb-3">
        {clips.length + 1} / {props.clipDescriptions.length}
      </p>

      <div className="border-2 border-lblue-500 overflow-hidden relative h-10 mb-3">
        {progressAnimation}

        <div
          className={clsx(
            'h-full flex items-center justify-center',
            'text-xl font-semibold text-orange-500',
            'z-20 opacity-position-hack'
          )}
        >
          {recorderState === 'countdown' ? (
            <Countdown from={3} onDone={() => setRecorderState('recording')} />
          ) : (
            <span>{wordDisplay}</span>
          )}
        </div>
      </div>

      <AudioVisualizer
        audioRecorder={mediaRecorder}
        paused={recorderState !== 'recording'}
      />

      <section className="py-4">
        {recorderState === 'paused' ? (
          <Button onClick={() => setRecorderState('countdown')}>Start</Button>
        ) : (
          <Button onClick={() => setRecorderState('paused')}>Pause</Button>
        )}
      </section>
    </>
  );
}
