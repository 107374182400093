import './init';
import { Gtag } from './types';
import { Mixpanel } from './mixpanel_types';

declare global {
  interface Window {
    csrf_token: string;
    page_component: string;
    gtag: Gtag.Gtag | undefined;
    mixpanel: Mixpanel | undefined;
  }
}

import './App';
