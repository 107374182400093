import AudioRecorder from 'audio-recorder-polyfill';
import { SystemError } from '../components/ErrorBoundary';

function ponyfillGetUserMedia() {
  // Source: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Using_the_new_API_in_older_browsers

  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    console.log('Polyfilling getUserMedia');
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      const getUserMedia =
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        console.log('  getUserMedia polyfill: Not implemented in this browser');
        return Promise.reject(new SystemError('getUserMedia_NOT_SUPPORTED'));
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  }
}

export default function polyfillMedia() {
  console.log(`Polyfilling MediaRecorder`);
  window.MediaRecorder = AudioRecorder;

  return ponyfillGetUserMedia();
}
