// Suspense integrations like Relay implement
// a contract like this to integrate with React.
// Real implementations can be significantly more complex.
// Don't copy-paste this into your project!
type PendingT = { status: 'pending' };
type SuccessT<T> = { status: 'success'; result: T };
type ErrorT = { status: 'error'; error: any };

type PromiseStateT<T> = PendingT | SuccessT<T> | ErrorT;

export function wrapPromiseForSuspense<T>(promise: Promise<T>) {
  let state: PromiseStateT<T> = { status: 'pending' };
  let suspender = promise.then(
    (result) => {
      Object.assign(state, { status: 'success', result });
    },
    (error) => {
      Object.assign(state, { status: 'error', error });
    }
  );

  return {
    read() {
      if (state.status === 'error') {
        throw state.error;
      } else if (state.status === 'success') {
        return state.result;
      }
      // state.status === 'pending'
      throw suspender;
    },
  };
}

export function createFunctionWithTimeout(
  callback: () => void,
  opt_timeout?: number
) {
  var called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1000);
  return fn;
}
