import * as React from 'react';
import { Button } from './components';
import { LegalText } from './components/LegalText';

export default function WelcomeScreen() {
  return (
    <div className="text-center py-12">
      <div className="text-base mb-10">
        <a className="inline-block" href="/">
          <Button>Back</Button>
        </a>
      </div>

      <section id="terms" className="text-base mb-10">
        <div className="mb-4">
          <h5 className="text-2xl lg:text-3xl font-semibold">
            Terms and conditions
          </h5>
        </div>

        <LegalText />
      </section>

      <section>
        <a className="inline-block" href="/">
          <Button>Back</Button>
        </a>
      </section>
    </div>
  );
}
