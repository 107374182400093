import * as React from 'react';
import LogoCircle from './LogoCircle';
import LogoText from './LogoText';

export function Header() {
  return (
    <header className="flex justify-center items-center pt-4">
      <LogoCircle className="text-orange-500 w-16 mr-6" />
      <LogoText className="text-orange-500 h-8" />
    </header>
  );
}
