import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { events } from '../analytics';

const ErrorContainer = (props: { children: React.ReactNode }) => (
  <div className="text-center py-4 px-2 mt-10 " children={props.children} />
);

type ErrorKeysT = 'getUserMedia_NOT_SUPPORTED' | 'NO_AUDIO_FORMATS_SUPPORTED';

export const ERRORS: Record<ErrorKeysT, () => JSX.Element> = {
  getUserMedia_NOT_SUPPORTED: () => (
    <ErrorContainer>
      <h3 className="text-base mb-3">
        Your browser doesn't support audio recording.
      </h3>

      <p className="text-xl leading-7">
        If you opened this in{' '}
        <span className="font-semibold">Facebook/Messenger</span>, please copy
        the link to your web browser to make the recording work
      </p>
    </ErrorContainer>
  ),
  NO_AUDIO_FORMATS_SUPPORTED: () => (
    <ErrorContainer>
      <p className="text-base leading-7">
        Your device does not support any of the audio formats used by this app
      </p>
    </ErrorContainer>
  ),
};

export class SystemError extends Error {
  public originalError?: Error;
  public MessageComponent: () => JSX.Element;
  constructor(key: ErrorKeysT, originalError?: any) {
    super(key);
    this.name = 'SystemError';
    this.MessageComponent = ERRORS[key];

    if (originalError instanceof Error) {
      this.originalError = originalError;
      this.name = `${this.name}<${originalError.name}>`;
      if (originalError.stack) {
        this.stack = (this.stack || '') + `\n${originalError.stack}`;
      }
    }

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, SystemError.prototype);
  }
}

type PropsT = {};
type StateT = { errorMessage?: string; errorRenderer?: () => JSX.Element };
export class ErrorBoundary extends React.Component<PropsT, StateT> {
  state: StateT = {};

  constructor(props: PropsT) {
    super(props);
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    console.log(error.message);
    console.log(JSON.stringify(error, null, 2));

    const errorMessage = error.message || 'An unexpected error occurred';

    try {
      window.gtag?.('event', events.ERROR);
      window.mixpanel?.track(events.ERROR, { errorMessage });
    } catch (error) {
      // no-op
    }

    // Update state so the next render will show the fallback UI.
    return { errorMessage };
  }

  componentDidCatch(error: Error, errorInfo: {}) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.errorRenderer) {
      return this.state.errorRenderer();
    }

    if (this.state.errorMessage) {
      return (
        <ErrorContainer>
          <p className="text-base leading-7">{this.state.errorMessage}</p>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}
