import * as React from 'react';
import { AudioClipT } from '../types';
import { Button } from '.';

export function SoundClips(props: {
  clips: AudioClipT[];
  onDelete: (clipId: string) => void;
}) {
  return (
    <section>
      {props.clips.map((clip) => (
        <article id={clip.id} key={clip.id} className="mb-6">
          <audio
            className="block w-full mb-2"
            controls={true}
            src={clip.audioURL}
            onError={(event) => {
              console.log(`Error in clip ${clip.id} (${clip.word})`, event);
            }}
          />
          <div className="flex justify-between">
            <p className="text-base clip-label">{clip.word}</p>
            <Button
              variant="text"
              color="danger"
              size="sm"
              children="Delete"
              onClick={(e) => {
                props.onDelete(clip.id);
              }}
            />
          </div>
        </article>
      ))}
    </section>
  );
}
