import * as React from 'react';
import { nanoid } from 'nanoid';

interface AnimationApi {
  run: (duration: number) => void;
  stop: () => void;
}

export function useProgressAnimation(): [React.ReactNode, AnimationApi] {
  const [animation, setAnimation] = React.useState({
    key: nanoid(),
    enabled: false,
    duration: 0,
  });

  const api = React.useMemo(
    (): AnimationApi => ({
      run: (duration: number) =>
        setAnimation({ key: nanoid(), enabled: true, duration }),
      stop: () => setAnimation({ key: nanoid(), enabled: false, duration: 0 }),
    }),
    []
  );

  let node: React.ReactNode = null;
  if (animation.enabled || true) {
    node = (
      <span
        key={animation.key}
        className="block absolute w-full h-full top-0 opacity-50 z-0 bg-blue-300 linear-progress-bar"
        style={{
          animationName: 'linear-progress',
          animationDuration: `${animation.duration / 1000}s`,
          animationDelay: '0s',
          animationTimingFunction: 'linear',
          animationIterationCount: 1,
        }}
      />
    );
  }

  return [node, api];
}
