import * as React from 'react';
import { Button, PageTitle } from './components';

export default function WelcomeScreen() {
  return (
    <>
      <section className="text-base text-center mt-12">
        <PageTitle>Help us bring our vision to life!</PageTitle>

        <p className="leading-relaxed mb-4">
          Hoopteller is building a collection of speech data of individual words
          spoken by a large number of people. The data collected will be used to
          make mobile application to enable record keeping.The collection is
          only to be used in our own models, they are completely anonymous, and{' '}
          <span className="font-semibold">not shared publicly</span>.
        </p>

        <p className="leading-relaxed mb-8">
          <span className="font-semibold">
            We will naturally never misuse anything you provide us!
          </span>
          <br />
          by participating you are agreeing to the{' '}
          <a href="/legal" className="text-lblue-300 underline">
            terms and conditions
          </a>
          .
        </p>
      </section>

      <section className="text-center mb-12">
        <a className="inline-block" href="/start">
          <Button size="xl">Proceed</Button>
        </a>

        <p className="italic text-sm text-lblue-700 mt-2">
          Recording session takes about{' '}
          <span className="font-semibold">3min</span>
        </p>
      </section>
    </>
  );
}
