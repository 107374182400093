import * as React from 'react';
import { useInterval } from '../hooks';

/**
 * Countdown hook
 */
export const useCountdown = (from: number, to = 0) => {
  const [count, setCount] = React.useState(from);
  const done = count === to;

  const decrement = React.useCallback(() => {
    if (!done) {
      setCount((currentCount) => currentCount - 1);
    }
  }, [done]);

  useInterval(decrement, 1000);
  return count;
};

/**
 * Countdown display
 */
interface CountdownPropsT {
  from?: number;
  onDone: () => void;
}
export function Countdown({ from = 3, onDone }: CountdownPropsT) {
  const count = useCountdown(from);

  React.useEffect(() => {
    if (count === 0) {
      onDone();
    }
  }, [count, onDone]);

  return <span>{count}</span>;
}
