// polyfills
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import 'object.values';

import * as Sentry from '@sentry/browser';
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      'https://44c40899a9cf4d20856f99eab1353485@o376721.ingest.sentry.io/5197864',
  });
}
