export type WordArrayT = string[];
export type WordCountsT = Record<string, number>;

export const countWords = (wordArr: WordArrayT): WordCountsT => {
  return wordArr.reduce<WordCountsT>((acc, word) => {
    acc[word] = (acc[word] || 0) + 1;
    return acc;
  }, {});
};

export const sumWordCounts = (wordCounts: WordCountsT) => {
  return Object.values(wordCounts).reduce((acc, count) => acc + count, 0);
};

export function shuffleArray<T>(originalArray: T[]): T[] {
  const array = [...originalArray];
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function unrollWordCounts(wordCounts: WordCountsT) {
  const result: string[] = [];
  for (const word in wordCounts) {
    let count = wordCounts[word];
    for (let i = 0; i < count; ++i) {
      result.push(word);
    }
  }
  return result;
}

const hoopWords: WordArrayT = [
  'Make',
  'Miss',
  'Undo',
  'Zone',
  'Start',
  'Pause',
  'Up',
  'Down',
  'Yes',
  'No',
  'Stop',
];

const relevantWords: WordArrayT = [
  'Zero',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'On',
  'Off',
  'Go',
  'Left',
  'Right',
];

const fillerWords: WordArrayT = [
  // existing filler words:
  'Dog',
  'Cat',
  'Bird',
  'Tree',
  'Marvin',
  'Sheila',
  'House',
  'Bed',
  'Wow',
  'Happy',
  // custom filler words:
  'Take',
  'Kiss',
];

export function getWantedWordCounts() {
  const wordCounts: WordCountsT = {};
  hoopWords.forEach((word) => {
    wordCounts[word] = 3; // original was 5
  });
  relevantWords.forEach((word) => {
    wordCounts[word] = 2;
  });
  fillerWords.forEach((word) => {
    wordCounts[word] = 1;
  });
  return wordCounts;
}
