import * as React from 'react';
import { nanoid } from 'nanoid';
import * as Sentry from '@sentry/browser';

import { SoundClips, Button, AudioRecorder, Uploader } from './components';
import { AudioClipT } from './types';
import { createMediaResource } from './audioRecorder';
import { getWantedWordCounts, shuffleArray, unrollWordCounts } from './words';
import { createFunctionWithTimeout } from './utils';
import { events } from './analytics';

export default function RecordingScreen() {
  type AppStateT = 'intro' | 'audioRecorder' | 'upload';
  const [appState, setAppState] = React.useState<AppStateT>('intro');

  const appStateRef = React.useRef(appState);
  React.useEffect(() => {
    const prevState = appStateRef.current;
    appStateRef.current = appState;

    if (prevState === 'intro' && appState === 'audioRecorder') {
      window.mixpanel?.time_event(events.RECORDING_COMPLETE);
    }
    if (prevState === 'audioRecorder' && appState === 'upload') {
      window.mixpanel?.track(events.RECORDING_COMPLETE);
      window.mixpanel?.time_event(events.UPLOAD_COMPLETE);
    }
  }, [appState]);

  const [mediaResource] = React.useState(() =>
    createMediaResource({
      wantedTypes: [
        'audio/ogg;codecs=opus',
        'audio/webm;codecs=opus',
        'audio/wav',
      ],
    })
  );

  const clipDescriptions = React.useMemo(() => {
    return shuffleArray(
      unrollWordCounts(getWantedWordCounts())
    ).map((word) => ({ id: nanoid(), word }));
  }, []);

  const [clips, setClips] = React.useState<AudioClipT[]>([]);
  const onAudioClip = React.useCallback((clip: AudioClipT) => {
    setClips((_clips) => _clips.concat(clip));
  }, []);
  const onDelete = React.useCallback((clipId: string) => {
    setClips((_clips) => _clips.filter((_clip) => _clip.id !== clipId));
  }, []);
  const onClipUploaded = React.useCallback((clipId: string) => {
    setClips((_clips) =>
      _clips.map((_clip) =>
        _clip.id === clipId ? { ..._clip, uploaded: true } : _clip
      )
    );
  }, []);

  return (
    <>
      <section className="pt-12 pb-8 text-center">
        {appState === 'intro' && (
          <>
            <div className="text-center text-base leading-5 mb-6">
              <p className="font-semibold leading-6 mb-3">
                Record yourself saying only the word in the box below.
              </p>
              <p className="leading-6 mb-3">
                Press "Pause" to stop recording, and when all the requested
                words are completed you'll be able to upload the recordings.
              </p>
            </div>

            <section className="py-2">
              <div className="mb-5">
                <Button size="lg" onClick={() => setAppState('audioRecorder')}>
                  Start recording
                </Button>
                <p className="italic text-base text-lblue-700 mt-2">
                  Please record in a quiet room
                </p>
              </div>
            </section>
          </>
        )}

        {appState === 'audioRecorder' && (
          <React.Suspense fallback={<h2>Loading api..</h2>}>
            <AudioRecorder
              clips={clips}
              clipDescriptions={clipDescriptions}
              onAudioClip={onAudioClip}
              mediaResource={mediaResource}
              onDone={() => setAppState('upload')}
            />
          </React.Suspense>
        )}

        {appState === 'upload' && (
          <Uploader
            clips={clips}
            onClipUploaded={onClipUploaded}
            onDone={() => {
              const redirectUser = createFunctionWithTimeout(() => {
                document.cookie = 'all_done=true; path=/';
                location.reload(true);
              }, 300);

              try {
                window.mixpanel?.track(
                  events.UPLOAD_COMPLETE,
                  { number_of_clips: clips.length },
                  redirectUser
                );
                window.gtag?.('event', events.UPLOAD_COMPLETE, {
                  event_callback: redirectUser,
                });
              } catch (error) {
                Sentry.captureException(error);
              }
            }}
          />
        )}
      </section>

      <div>
        <SoundClips clips={clips} onDelete={onDelete} />
      </div>
    </>
  );
}
