import * as React from 'react';
import { createAudioContext } from '../audioRecorder/audioApi';

let _audioContext: AudioContext | undefined;

const FILL_COLOR = 'rgb(87, 93, 105)';
const STROKE_COLOR = 'rgb(224, 119, 71)';

export function AudioVisualizer(props: {
  audioRecorder: MediaRecorder;
  paused?: boolean;
}) {
  const { audioRecorder, paused = false } = props;

  /**
   * Initialize audio apis
   */
  const audioContext = React.useMemo(
    () => _audioContext || createAudioContext(),
    []
  );

  const audioAnalyser = React.useMemo(() => {
    const _analyser = audioContext.createAnalyser();
    _analyser.fftSize = 2048;
    return _analyser;
  }, []);

  const mediaStreamSource = React.useMemo(() => {
    return audioContext.createMediaStreamSource(audioRecorder.stream.clone());
  }, [audioRecorder, audioContext]);

  /**
   * Connect source and analyser, and run animation
   */
  React.useEffect(() => {
    // TODO: Cannot pause and restart visualization
    // if (paused) { return; }

    mediaStreamSource.connect(audioAnalyser);
    return () => {
      mediaStreamSource.disconnect();
    };
  }, [mediaStreamSource, audioAnalyser]);

  // canvas drawing
  const [runAnimation, setRunAnimation] = React.useState(false);
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  React.useEffect(() => {
    if (!canvasRef.current || !runAnimation) {
      return;
    }

    const canvas = canvasRef.current;
    const bufferLength = audioAnalyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    const canvasCtx = canvas.getContext('2d');

    let animationFrameId: number | undefined;
    draw();
    function draw() {
      animationFrameId = requestAnimationFrame(draw);
      audioAnalyser.getByteTimeDomainData(dataArray);

      canvasCtx!.fillStyle = FILL_COLOR;
      canvasCtx!.fillRect(0, 0, WIDTH, HEIGHT);
      canvasCtx!.lineWidth = 2;
      canvasCtx!.strokeStyle = STROKE_COLOR;
      canvasCtx!.beginPath();

      const sliceWidth = (WIDTH * 1.0) / bufferLength;
      let x = 0;
      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * HEIGHT) / 2;
        if (i === 0) {
          canvasCtx!.moveTo(x, y);
        } else {
          canvasCtx!.lineTo(x, y);
        }
        x += sliceWidth;
      }

      canvasCtx!.lineTo(canvas.width, canvas.height / 2);
      canvasCtx!.stroke();
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [canvasRef, runAnimation, audioAnalyser]);

  return (
    <canvas
      className="block w-full h-16 mb-2"
      ref={(el) => {
        if (el) {
          canvasRef.current = el;
          setRunAnimation(true);
        }
      }}
    />
  );
}
