import { AudioClipT } from './types';

export function uploadAudioClip(audioClip: AudioClipT) {
  const { word, blob } = audioClip;

  const mimeType = encodeURI(audioClip.mimeType);
  const csrf_token = window.csrf_token;

  return fetch(
    `/upload?word=${word}&mimeType=${mimeType}&_csrf_token=${csrf_token}`,
    {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      body: blob,
    }
  ).then((res) => {
    if (res.ok) {
      return;
    } else {
      const error = new Error(res.statusText);
      // @ts-ignore
      error.response = res;
      return Promise.reject(error);
    }
  });
}
