import { wrapPromiseForSuspense } from '../utils';
import { SystemError } from '../components';

function polyfillMediaRecorder() {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    // @ts-ignore
    navigator.mediaDevices = {};
  }

  if (!navigator.mediaDevices.getUserMedia) {
    console.log('Missing navigator.mediaDevices.getUserMedia');
  }
  if (!window.MediaRecorder) {
    console.log('Missing window.MediaRecorder');
  }

  if (window.MediaRecorder && navigator.mediaDevices.getUserMedia) {
    return Promise.resolve();
  }

  return import('./polyfill').then((module) => module.default());
}

export interface MediaResourceOptionsT {
  wantedTypes: string[];
}
interface PolyfilledMediaRecorder extends MediaRecorder {
  getMimeType: () => string;
}

export function createMediaResource(options: MediaResourceOptionsT) {
  const audioRecorderP = Promise.resolve()
    .then(() => polyfillMediaRecorder())
    .then(() => initMediaRecorder(options));

  return wrapPromiseForSuspense(audioRecorderP);
}
export type MediaResourceT = ReturnType<typeof createMediaResource>;

/**
 * Notes on mime types for audio:
 * - Safari supports audio/wav
 * - Firefox supports: audio/ogg, audio/ogg;codecs=opus, audio/webm, audio/webm;codecs=opus
 * - Wav is 10MB/s, but can be reduced to limiting channels to 1 instead of 2
 *   - https://blog.addpipe.com/using-webaudiorecorder-js-to-record-audio-on-your-website/
 */
export function initMediaRecorder(
  options: MediaResourceOptionsT
): Promise<PolyfilledMediaRecorder> {
  if (!navigator.mediaDevices.getUserMedia) {
    throw new SystemError('getUserMedia_NOT_SUPPORTED');
  }

  const supportedTypes = options.wantedTypes.filter((mimeType) =>
    MediaRecorder.isTypeSupported(mimeType)
  );

  console.log('Supported media types', supportedTypes);

  const selectedMimeType = supportedTypes[0];
  if (!selectedMimeType) {
    throw new SystemError('NO_AUDIO_FORMATS_SUPPORTED');
  }

  return navigator.mediaDevices
    .getUserMedia({ audio: true, video: false })
    .then((stream) => {
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: selectedMimeType,
      }) as PolyfilledMediaRecorder;

      // Firefox on Android doesn't define .mimeType
      mediaRecorder.getMimeType = () => {
        return mediaRecorder.mimeType || selectedMimeType;
      };

      return mediaRecorder;
    });
}

export const createAudioContext = (): AudioContext => {
  // @ts-ignore
  const AudioCtx = window.AudioContext || window.webkitAudioContext;
  return new AudioCtx();
};
