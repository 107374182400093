import * as React from 'react';
import { PageTitle } from './components/PageTitle';
import { events } from './analytics';

const SECTION_ID = 'thanks';

export default function WelcomeScreen() {
  React.useEffect(() => {
    window.mixpanel?.track_links(
      `#${SECTION_ID} a`,
      events.CLICKED_THANK_YOU_LINK
    );
  }, []);

  return (
    <>
      <section id={SECTION_ID} className="text-base text-center mt-12">
        <PageTitle>Thank you for your help!</PageTitle>

        <p className="leading-relaxed mb-12">
          If you want Hoopteller to become a reality sooner, spread this page
          and help us get even more feedback!
        </p>

        <p className="leading-relaxed mb-2">
          <span className="font-semibold">
            To get exclusive access to prototypes
          </span>
          <br />
          visit:
        </p>

        <a
          className="text-xl lg:text-2xl uppercase font-semibold"
          href="https://hoopteller.com"
          children="hoopteller.com"
        />

        <h5 className="text-lg font-semibold mt-12 mb-2">
          From SurveySwap.io?
        </h5>
        <a
          className="underline text-lblue-300"
          href="https://surveyswap.io/sr/Brth5VD2I4wZ9Uc9"
          children="Click here to claim credits"
        />
      </section>
    </>
  );
}
